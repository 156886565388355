import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Flex,
    HStack,
    Heading,
    List,
    ListItem,
    SimpleGrid,
    Text,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Effect as IEffect } from "../api/game"
import GameIcon, { ICONS } from "../components/common/GameIcon"
import Loading from "../components/common/Loading"
import Number from "../components/common/Number"
import Progress from "../components/common/ProgressWithText"
import { Leaderboard } from "../components/stats/Leaderboards"
import { useEffects, useInvasionInfo, useIsUnlocked } from "../hooks/query"
import { FactionStat, Factions, ResourceType, UnlockType } from "../types"
import { getKeys } from "../utils"
import HelpText from "../components/common/HelpText"
import { Victory } from "../components/stats/Victory"

interface EffectProps extends IEffect {
    name: string
    percentage?: boolean
    help?: string
}

const Effect: FunctionComponent<EffectProps> = ({ name, details, total, percentage = false, help }) => {
    const hasIcon = Object.keys(ICONS).includes(name)
    const { t } = useTranslation()
    const tname = hasIcon ? t(`resources.${name}.title`) : name
    const numOfFractionDigits = ["soldiers", "workers"].includes(name) ? 2 : 1

    // little hack because first talent points is not considered as a talent
    if (name == "Talent points") {
        total += 1
    }
    return (
        <Box>
            <Box fontSize="xl" display="flex" justifyContent={"space-between"}>
                <Box display="flex" alignItems={"center"}>
                    {hasIcon && <GameIcon name={name as keyof typeof ICONS} me="2" />}
                    {help ? (
                        <HelpText tooltip={help}>
                            <>{tname}</>
                        </HelpText>
                    ) : (
                        <>{tname}</>
                    )}
                </Box>
                <Number value={total} percentage={percentage} numOfFractionDigits={numOfFractionDigits} />
            </Box>
            {total !== 0 && (
                <Box>
                    <List ms={8}>
                        {details.map((detail) => {
                            const fromLabel = !["terrain", "projects", "talents", "achievements", "improvements", "events", "balance"].includes(detail.from)
                                ? t(`buildings.${detail.from}.title`)
                                : t(`from.${detail.from}.title`)
                            return (
                                <ListItem key={detail.from} borderBottom={"1px solid"} borderColor="orange.600">
                                    <HStack justifyContent={"space-between"}>
                                        <Text as="span">{fromLabel} : </Text>
                                        <HStack>
                                            {detail.base ? (
                                                <Number value={detail.base} numOfFractionDigits={numOfFractionDigits} />
                                            ) : null}
                                            {detail.bonus ? <Number value={detail.bonus} percentage showPlus /> : null}
                                        </HStack>
                                    </HStack>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            )}
        </Box>
    )
}

const Stats: FunctionComponent = observer(() => {
    const { data: invasionInfo, isLoading: isLoadingInvasion } = useInvasionInfo()
    const { data: effects } = useEffects()
    const showGuardian = useIsUnlocked(UnlockType.UI_GUARDIAN)
    const showKnight = useIsUnlocked(UnlockType.UI_KNIGHT)
    const showWorker = useIsUnlocked(UnlockType.UI_PROJECTS)
    const showSpec = useIsUnlocked(UnlockType.UI_SPECIALIZATION)

    const world = invasionInfo?.world
    if (!world) return <></>

    const invasions = {
        [Factions.RED]: 0,
        [Factions.YELLOW]: 0,
        [Factions.BLUE]: 0,
        [Factions.GREEN]: 0,
    }
    const soldiers = {
        [Factions.RED]: 0,
        [Factions.YELLOW]: 0,
        [Factions.BLUE]: 0,
        [Factions.GREEN]: 0,
    }

    const total = world.length * world.length
    let totalSoldiers = 0
    for (let x = 0; x < world.length; x++) {
        for (let y = 0; y < world[x].length; y++) {
            const faction = world[x][y].f
            
            if (faction && faction !== Factions.NEUTRAL) {
                invasions[faction] += 1
                if (world[x][y].s) {
                    soldiers[faction] += world[x][y].s
                    totalSoldiers += world[x][y].s
                }
            }
        }
    }

    const territoryStats: FactionStat[] = []
    getKeys(invasions).map((faction) => {
        const percentage = (invasions[faction] * 100) / total
        territoryStats.push({
            faction: faction,
            percentage,
            value: invasions[faction],
        })
    })
    territoryStats.sort(function (a, b) {
        return b.value - a.value
    })

    const soldiersStats: FactionStat[] = []
    getKeys(invasions).map((faction) => {
        const percentage = (soldiers[faction] * 100) / totalSoldiers
        soldiersStats.push({
            faction: faction,
            percentage,
            value: soldiers[faction],
        })
    })
    soldiersStats.sort(function (a, b) {
        return b.percentage - a.percentage
    })

    if (!effects) return <Loading />
    return (
        <>
            <Text variant="discreet" pb="2">Stats are not updated in real-time.</Text>
            <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={10}>
                <Victory />
                <Card variant="elevated">
                    <CardHeader>
                        <Heading size="md">Ranking</Heading>
                    </CardHeader>

                    <CardBody>
                        <Heading size="sm">Territory size</Heading>
                        <Flex flexDirection={"column"} height={"full"} mt="4">
                            {territoryStats.map((stat) => (
                                <Progress
                                    key={stat.faction}
                                    value={stat.percentage}
                                    total={100}
                                    h="35px"
                                    progressColor={stat.faction}
                                >
                                    <Text fontSize="xl" p={1} as="span">
                                        {stat.faction} : <Number value={stat.percentage} percentage /> (
                                        <Number value={stat.value} />)
                                    </Text>
                                </Progress>
                            ))}
                        </Flex>
                    </CardBody>
                    <CardBody>
                        <Heading size="sm">Active soldiers</Heading>
                        <Flex flexDirection={"column"} height={"full"} mt="4">
                            {soldiersStats.map((stat) => (
                                <Progress
                                    key={stat.faction}
                                    value={stat.percentage}
                                    total={100}
                                    h="35px"
                                    progressColor={stat.faction}
                                >
                                    <Text fontSize="xl" p={1} as="span">
                                        {stat.faction} : <Number value={stat.percentage} percentage /> (
                                        <Number value={stat.value} />)
                                    </Text>
                                </Progress>
                            ))}
                        </Flex>
                    </CardBody>
                </Card>

                <Leaderboard />

                <Card variant="elevated">
                    <CardHeader>
                        <Heading size="md">Production</Heading>
                    </CardHeader>

                    <CardBody>
                        <Effect name={ResourceType.WOOD} {...effects?.production.wood} />
                        <Effect name={ResourceType.IRON} {...effects?.production.iron} />
                        <Effect name={ResourceType.SOLDIER} {...effects?.production.soldiers} />
                        <Effect name={ResourceType.WORKER} {...effects?.production.workers} />
                        {showGuardian && <Effect name={ResourceType.GUARDIAN} {...effects?.production.guardian} />}
                        {showKnight && <Effect name={ResourceType.KNIGHT} {...effects?.production.knight} />}
                    </CardBody>
                </Card>

                <Card variant="elevated">
                    <CardHeader>
                        <Heading size="md">Storage</Heading>
                    </CardHeader>

                    <CardBody>
                        <Effect name={ResourceType.WOOD} {...effects?.storage.wood} />
                        <Effect name={ResourceType.IRON} {...effects?.storage.iron} />
                        <Effect name={ResourceType.SOLDIER} {...effects?.storage.soldiers} />
                        <Effect name={ResourceType.WORKER} {...effects?.storage.workers} />
                    </CardBody>
                </Card>

                <Card variant="elevated">
                    <CardHeader>
                        <Heading size="md">Military & Projects</Heading>
                    </CardHeader>

                    <CardBody>
                        <Effect
                            name="Attack bonus"
                            {...effects?.world.attack}
                            percentage
                            help={"Add more soldiers when attacking a territory."}
                        />
                        <Effect
                            name="Defense bonus"
                            {...effects?.world.defense}
                            percentage
                            help={"Add more soldiers when reinforcing a territory."}
                        />
                        {showWorker && (
                            <Effect
                                name="Worker efficiency"
                                {...effects?.world.worker}
                                percentage
                                help={"Add more workers when affecting workers to a project."}
                            />
                        )}
                        {(showGuardian || showKnight) && (
                            <>
                                <Effect
                                    name="Knight bonus"
                                    {...effects?.world.knightPower}
                                    percentage
                                    help={
                                        "Add more soldiers when attacking a territory. Base bonus is 20%. A 50% increase means the bonus becomes 30%."
                                    }
                                />
                                <Effect
                                    name="Guardian bonus"
                                    {...effects?.world.guardianPower}
                                    percentage
                                    help={
                                        "Enemy adds less soldiers when attacking a territory with guardians. Base bonus is 20%. A 50% increase means the bonus becomes 30%."
                                    }
                                />
                            </>
                        )}
                    </CardBody>
                </Card>

                {showSpec && (
                    <Card variant="elevated">
                        <CardHeader>
                            <Heading size="md">Misc.</Heading>
                        </CardHeader>

                        <CardBody>
                            {showSpec && <Effect name="Talent points" {...effects?.talent.talent} />}
                            {/* <Effect name="bonus" {...effects?.world.attack} />
                    <Effect name="Attack bonus" {...effects?.world.attack} /> */}
                        </CardBody>
                    </Card>
                )}
            </SimpleGrid>
        </>
    )
})

export default Stats
