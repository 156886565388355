import { makeObservable, observable, action, set, toJS } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { clamp, getKeys } from "../utils"
import { Invasion } from "../components/world/objects/invasion"
import { FactionTileInfo } from "../api/world"
import { Factions, SupportType, TerrainType } from "../types"
import { queryClient } from "../query_client"
import { Config, Effects } from "../api/game"
import { MINIMAL_SUPPORT_TO_INVADE, WORLD_SIZE } from "../config"
import stringify from 'json-stable-stringify'
import { sha256 } from "js-sha256"
import { TILE_SIZE } from "../components/world/config"
import { FactionData } from "../api/faction"


let delayedTimeout: null | number
const OUT_OF_SYNC_THRESHOLD = 1
const RETRY_DELAY_MAX = 1000 * 30
export interface Support {
    supportType: SupportType
    faction: Factions
    created: Date
    player: string
    power: number
}
export interface TileInfo {
    x: number
    y: number
    faction: Factions
    soldiers: number
    supplied: boolean
    knights?: [[[number, string]], [[number, string]], [[number, string]], [[number, string]]]
    guardians?: [[number, string]]
    fortification: number
    attack: number
    captureDate?: Date | null // timestamp
    capturePlayer?: string
    lostSupplyDate?: Date | null // timestamp
    isValidForInvasion: { valid: boolean, reason?: string }
    isValidForPillage: { valid: boolean, reason?: string }
    isValidForKnight: { valid: boolean, reason?: string }
    isValidForGuardian: { valid: boolean, reason?: string }
    victoryPoints: number
    fortificationDetails: {
        allowed: boolean
        visible: boolean
        level: number
        workers: number
        bonus: number
        currentGoalWorkers: number
        goal: number
    }
    improvements: {
        faction: Factions
        own: boolean
        allowed: boolean
        visible: boolean
        level: number
        workers: number
        bonus: number
        currentGoalWorkers: number
        goal: number
        canDismantle: boolean
    }[]
    homeBonus: {
        faction: Factions
        value: number
    } | null
}

export default class MapStore extends BaseStore {

    tileInfos: FactionTileInfo[][] = []
    tileInfosGenerated: Date | null = null
    selectedTile: { x: number, y: number } | null = null
    /** delay the call to get tile info to prevent too many requests */
    runFetch: boolean = false
    /** is phaser completely booted */
    booted: boolean = false
    failedCheck: number = 0

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            tileInfos: observable,
            tileInfosGenerated: observable,
            selectedTile: observable,
            runFetch: observable,
            booted: observable,
            // incrementFailedSyncCheck: action,
            resetFailedSyncCheck: action,
            setTileInfos: action,
            setTileInfo: action,
            addSupplyChainDiff: action,
            setTileInfosGenerated: action,
            setSelectedTile: action,
            unselectTile: action,
            setRunFetch: action,
            setBooted: action
        })
    }


    /** compute a hash of the invasion map to compare with server */
    hashMap(): string {
        const hash = sha256.create()
        const tileInfosCopy: FactionTileInfo[][] = []
        for (let x = 0; x < WORLD_SIZE; x++) {
            tileInfosCopy[x] = []
            for (let y = 0; y < WORLD_SIZE; y++) {
                // remove supply date to avoid hash desync with the server
                tileInfosCopy[x][y] = { ...this.tileInfos[x][y], cd: null }
            }
        }
        hash.update(stringify(tileInfosCopy));
        return hash.hex();
    }
    /** after 3 consecutive failed, we invalidate world info */
    // incrementFailedSyncCheck() {
    //     this.failedCheck += 1
    //     console.log("Sync failed check", this.failedCheck)
    //     if (this.failedCheck >= OUT_OF_SYNC_THRESHOLD) {
    //         console.log("Sync check failed : invalidating worldInfo")
    //         // invalidate world info after a random amount of time to avoid too many request at the same time
    //         setTimeout(() => {
    //             queryClient.invalidateQueries({ queryKey: ["worldInfo", this.rootStore.userStore.gameId] })
    //         }, Math.random() * RETRY_DELAY_MAX)

    //         this.failedCheck = 0
    //     }
    // }

    /** check successfull : reset failed check count */
    resetFailedSyncCheck() {
        this.failedCheck = 0
    }

    /** set the whole object */
    setTileInfos(infos: FactionTileInfo[][]) {

        this.tileInfos = infos
    }

    /** fill tileInfos with supply chain diff info */
    addSupplyChainDiff(diff: { add: [], remove: [] }) {

        if (diff.add) {
            for (const pos of diff.add) {
                this.tileInfos[pos[0]][pos[1]].c = true
                this.tileInfos[pos[0]][pos[1]].cd = null
            }
        }
        if (diff.remove) {
            for (const pos of diff.remove) {
                this.tileInfos[pos[0]][pos[1]].c = false
                this.tileInfos[pos[0]][pos[1]].cd = new Date().valueOf() / 1000
            }
        }
    }

    /** not used ? */
    setTileInfosGenerated(value: Date) {
        this.tileInfosGenerated = value
    }

    /** set a single tile */
    setTileInfo(x: number, y: number, info: FactionTileInfo) {
        this.tileInfos[x][y] = info
    }

    getCurrentSupportPosition(): { x: number, y: number } | null {

        const player = this.rootStore.userStore.player
        if (!player) return null
        const faction = player.faction
        if (!this.tileInfos.length) return null
        for (let x = 0; x < WORLD_SIZE; x++) {
            for (let y = 0; y < WORLD_SIZE; y++) {
                if (this.tileInfos[x][y].g) {
                    for (const g of this.tileInfos[x][y].g || []) {
                        if (g[1] === player.username) {
                            return { x, y }
                        }
                    }
                }
                if (this.tileInfos[x][y].k) {
                    for (const k of this.tileInfos[x][y].k || []) {
                        for (const factionK of k) {
                            if (factionK[1] === player.username) {
                                return { x, y }
                            }
                        }
                    }
                }
            }
        }
        return null
    }

    /** get a single tile many information for SelectedTile */
    getTileInfo(x: number, y: number): TileInfo | null {
        const gameId = this.rootStore.userStore.gameId
        const config = queryClient.getQueryData(["config", gameId]) as Config
        const factionsConfig = queryClient.getQueryData(["factions", gameId]) as FactionData[]
        const now = new Date()
        const playerFaction = this.rootStore.userStore.player?.faction

        if (this.tileInfos.length === 0) return null
        const tileInfo = this.tileInfos[x][y]

        const lostSupplyDate = tileInfo.cd ? new Date(tileInfo.cd * 1000) : null

        let fortificationLevel = 0
        const fortificationWorkers = tileInfo.w ? tileInfo.w[0] : 0
        let fortificationGoal = config.misc.fortificationTileLevels[0]
        let fortificationCurrent = fortificationWorkers
        if (fortificationWorkers >= config.misc.fortificationTileLevels[3]) {
            fortificationLevel = 4
        } else if (fortificationWorkers >= config.misc.fortificationTileLevels[2]) {
            fortificationLevel = 3
            fortificationGoal = config.misc.fortificationTileLevels[3] - config.misc.fortificationTileLevels[2]
            fortificationCurrent = fortificationWorkers - config.misc.fortificationTileLevels[2]
        }
        else if (fortificationWorkers >= config.misc.fortificationTileLevels[1]) {
            fortificationLevel = 2
            fortificationGoal = config.misc.fortificationTileLevels[2] - config.misc.fortificationTileLevels[1]
            fortificationCurrent = fortificationWorkers - config.misc.fortificationTileLevels[1]
        }
        else if (fortificationWorkers >= config.misc.fortificationTileLevels[0]) {
            fortificationLevel = 1
            fortificationGoal = config.misc.fortificationTileLevels[1] - config.misc.fortificationTileLevels[0]
            fortificationCurrent = fortificationWorkers - config.misc.fortificationTileLevels[0]
        }
        const fortificationAllowed = config.misc.allowedFortification.includes(config.world[x][y]) && (tileInfo.c || false) && tileInfo.f === playerFaction

        const improvements = []
        for (const [i, faction] of [Factions.BLUE, Factions.GREEN, Factions.RED, Factions.YELLOW].entries()) {
            let improvementLevel = 0
            const improvementWorkers = tileInfo.w ? tileInfo.w[1][i] : 0
            let improvementGoal = config.misc.improvementTileLevels[0]
            let improvementCurrent = improvementWorkers
            if (improvementWorkers >= config.misc.improvementTileLevels[3]) {
                improvementLevel = 4
            } else if (improvementWorkers >= config.misc.improvementTileLevels[2]) {
                improvementLevel = 3
                improvementGoal = config.misc.improvementTileLevels[3] - config.misc.improvementTileLevels[2]
                improvementCurrent = improvementWorkers - config.misc.improvementTileLevels[2]
            }
            else if (improvementWorkers >= config.misc.improvementTileLevels[1]) {
                improvementLevel = 2
                improvementGoal = config.misc.improvementTileLevels[2] - config.misc.improvementTileLevels[1]
                improvementCurrent = improvementWorkers - config.misc.improvementTileLevels[1]
            }
            else if (improvementWorkers >= config.misc.improvementTileLevels[0]) {
                improvementLevel = 1
                improvementGoal = config.misc.improvementTileLevels[1] - config.misc.improvementTileLevels[0]
                improvementCurrent = improvementWorkers - config.misc.improvementTileLevels[0]
            }
            const improvementAllowed = config.misc.allowedImprovement.includes(config.world[x][y]) && (tileInfo.c || false) && tileInfo.f === playerFaction
            improvements.push({
                faction: faction,
                own: faction === playerFaction,
                allowed: improvementAllowed,
                visible: improvementWorkers > 0 || (faction === tileInfo.f && faction == playerFaction),
                level: improvementLevel,
                workers: improvementWorkers,
                bonus: config.misc.tileImprovementBonus * improvementLevel,
                currentGoalWorkers: improvementCurrent,
                goal: improvementGoal,
                canDismantle: tileInfo.f == playerFaction && improvementWorkers > 0
            })
        }
        // compute tile defense bonus

        let tileFortificationBonus = 5
        const tileFactionConfig = factionsConfig?.find(f => f.id === tileInfo.f)
        if (tileFactionConfig)
            tileFortificationBonus = tileFactionConfig.effects.fortification.total


        let fortificationBonus = tileFortificationBonus * fortificationLevel
        if (tileInfo.g) {
            for (const g of tileInfo.g) {
                fortificationBonus += g[0]
            }
        }
        if (lostSupplyDate && tileInfo.f !== Factions.NEUTRAL) {
            const lostSupplyHours = Math.floor((now.getTime() - lostSupplyDate.getTime()) / 1000 / 60 / 60)
            if (lostSupplyHours >= 1) {
                fortificationBonus -= 20
            }
        }
        let homeBonusValue = 0
        let homeBonusFaction: Factions | null = null
        // check what faction has a home bonus
        for (const faction of getKeys(config.homeBonus)) {
            homeBonusFaction = faction as Factions
            homeBonusValue = homeBonusFaction ? config.homeBonus[homeBonusFaction][`${x},${y}`] || 0 : 0
            if (homeBonusValue > 0) {
                break
            }
        }
        // add home bonus if the tile is owned by the player
        // remove home bonus if the tile is owned by the enemy
        if (homeBonusFaction === tileInfo.f) {
            fortificationBonus += homeBonusValue
        }
        else if (homeBonusFaction === playerFaction) {
            fortificationBonus -= homeBonusValue
        }

        // compute tile attack bonus
        let attack = 0
        if (tileInfo.k) {
            let index = 0
            if (playerFaction === Factions.BLUE)
                index = 0
            if (playerFaction === Factions.GREEN)
                index = 1
            if (playerFaction === Factions.RED)
                index = 2
            if (playerFaction === Factions.YELLOW)
                index = 3
            for (const k of tileInfo.k[index]) {
                attack += k[0]
            }
        }

        return {
            x,
            y,
            faction: tileInfo.f,
            soldiers: tileInfo.s,
            supplied: tileInfo.c || false,
            knights: tileInfo.k,
            guardians: tileInfo.g,
            fortification: fortificationBonus,
            attack: attack,
            captureDate: tileInfo.d ? new Date(tileInfo.d * 1000) : null,
            capturePlayer: tileInfo.p,
            lostSupplyDate: lostSupplyDate,
            isValidForInvasion: this.isValidForInvasion(x, y, "soldiers"),
            isValidForPillage: this.isValidForInvasion(x, y, "pillage"),
            isValidForKnight: this.isValidForInvasion(x, y, "knight"),
            isValidForGuardian: this.isValidForInvasion(x, y, "guardian"),
            victoryPoints: tileInfo.v || 0,
            fortificationDetails: {
                allowed: fortificationAllowed,
                visible: fortificationLevel >= 1 || fortificationAllowed,
                level: fortificationLevel,
                workers: fortificationWorkers,
                bonus: tileFortificationBonus * fortificationLevel,
                currentGoalWorkers: fortificationCurrent,
                goal: fortificationGoal
            },
            improvements,
            homeBonus: (homeBonusValue > 0 && homeBonusFaction) ? {
                faction: homeBonusFaction,
                value: homeBonusValue
            } : null
        }
    }



    /** check if tile is valid for invasion */
    isValidForInvasion(x: number, y: number, type: "soldiers" | "knight" | "guardian" | "pillage") {
        const tileInfo = this.tileInfos[x][y]
        const playerFaction = this.rootStore.userStore.player?.faction
        if (!playerFaction)
            return { valid: false, reason: "No faction" }
        const config = queryClient.getQueryData(["config", this.rootStore.userStore.gameId]) as Config
        if (type === "pillage" && playerFaction === tileInfo.f)
            return { valid: false, reason: "pillage" }
        if (type != "pillage" && (config.world[x][y] === TerrainType.HQ_BLUE || config.world[x][y] === TerrainType.HQ_GREEN || config.world[x][y] === TerrainType.HQ_RED || config.world[x][y] === TerrainType.HQ_YELLOW))
            return { valid: false, reason: "hq" }

        if (config.mapConfig.forbidden_terrain_types.includes(config.world[x][y]))
            return { valid: false, reason: "forbidden" }

        if (type === "guardian" && tileInfo.f !== playerFaction) {
            return { valid: false }
        }

        if (type === "knight" && tileInfo.f === playerFaction) {
            return { valid: false }
        }

        const adjacents = [
            this.tileInfos[x - 1]?.[y],
            this.tileInfos[x + 1]?.[y],
            this.tileInfos[x]?.[y - 1],
            this.tileInfos[x]?.[y + 1]
        ]

        // check supply route
        let hasSupply = false

        for (const adjacent of adjacents) {
            if (adjacent && adjacent.f && adjacent.f === playerFaction && adjacent.c) {
                hasSupply = true
                break
            }
        }
        if (!hasSupply)
            return { "valid": false, "reason": "no supply route" }

        if (tileInfo.f === playerFaction)
            return { valid: true }
        // check supports

        let hasSupport = false
        for (const pos of [[x - 1, y], [x + 1, y], [x, y - 1], [x, y + 1]]) {
            const xtileInfo = this.tileInfos[pos[0]]?.[pos[1]]
            if (xtileInfo && xtileInfo.f === playerFaction && (config.world[pos[0]][pos[1]] === TerrainType.HQ_BLUE || config.world[pos[0]][pos[1]] === TerrainType.HQ_GREEN || config.world[pos[0]][pos[1]] === TerrainType.HQ_RED || config.world[pos[0]][pos[1]] === TerrainType.HQ_YELLOW))
                hasSupport = true
        }
        for (const adjacent of adjacents) {
            if (adjacent && adjacent.f && adjacent.f === playerFaction && adjacent.s >= MINIMAL_SUPPORT_TO_INVADE) {
                hasSupport = true
                break
            }
        }
        if (!hasSupport)
            return { "valid": false, "reason": "no support" }

        return { valid: true }
    }

    /** select a tile */
    setSelectedTile(x: number, y: number) {
        x = clamp(x, 0, 49)
        y = clamp(y, 0, 49)
        if (delayedTimeout)
            clearTimeout(delayedTimeout)
        if (this.selectedTile === null || this.selectedTile.x != x || this.selectedTile.y != y) {

            this.setRunFetch(false)
            this.selectedTile = { x, y }
            delayedTimeout = setTimeout(() => {
                this.setRunFetch(true)
            }, 300)
        }
    }

    /** move the selected tile in a x/y direction */
    move(x: number, y: number) {
        if (this.selectedTile) {
            this.setSelectedTile(this.selectedTile.x + x, this.selectedTile.y + y)
        }
    }

    /** unselect a tile */
    unselectTile() {
        this.selectedTile = null
    }

    setRunFetch(value: boolean) {
        this.runFetch = value
    }

    setBooted(value: boolean) {
        this.booted = value
    }

}
