import { Box, HStack, Heading, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Loading from "../components/common/Loading"
import { useConfig, useGame, usePlayer } from "../hooks/query"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Button from "../components/common/Button"
import { useNavigate } from "react-router"
import { GameMode, GameModeInfo, GameStatus, getGames, joinGame } from "../api/games"
import { getGroup } from "../api/player"
import { MAX_PLAYERS_IN_GROUP } from "../config"
import Link from "../components/common/Link"
import HelpText from "../components/common/HelpText"
import { useNews } from "../api/news"
import DateComponent from "../components/common/DateComponent"
import Panel from "../components/common/Panel"

const NewsPage: FunctionComponent = () => {
    const queryClient = useQueryClient()
    const { data: news } = useNews()


    return (
        <Box p="4">
            <Heading as="h1">News</Heading>
            {news?.map((item) => (
                <Panel key={item.id}>
                    <Heading as="h2" size="lg" textAlign="center">{item.title}</Heading>
                    <div className="html-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                    <HStack justifyContent="end">
                        <Text size="sm" color="orange.700" p="2"><DateComponent date={item.created_at} /></Text>
                    </HStack>
                </Panel>
            ))}

        </Box>
    )
}

export default NewsPage
