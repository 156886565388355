import { Box, Text } from "@chakra-ui/react"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import ReactFlow, {
    ReactFlowProvider,
    Panel,
    MiniMap,
    useNodesState,
    useEdgesState,
    useReactFlow,
    useViewport,
    useNodesInitialized,
    CoordinateExtent,
    Node,
    Edge,
} from "reactflow"
import { default as ProjectComponent } from "./Project"

import "reactflow/dist/style.css"


const projectsToNode = (projects: Project[] | undefined) => {
    if (!projects) return []
    return projects.map((project) => {
        // get number of projects in the same tier and its index
        const tier = project.tier
        const projectsInTier = projects.filter((p) => p.tier === tier)
        const numberOfProjectsInTier = projectsInTier.length
        const index = projectsInTier.indexOf(project)
        const y = tier * 400
        const size = 2000 / numberOfProjectsInTier
        const x = size * index + size / 2
        return {
            id: project.id.toString(),
            type: "project",
            data: { project: project.id, tier: project.tier },
            position: { x: x, y: y },
            draggable: false,
            selectable: true,
        }
    })
}

const projectsToEdges = (projects: Project[] | undefined) => {
    if (!projects) return []
    const data: Edge[] = []
    for (const project of projects) {
        for (const req of project.requires || []) {
            data.push({
                id: req + "-" + project.id,
                source: req.toString(),
                target: project.id.toString(),
                animated: project.unlocked,
                type: "smoothstep",
                style: { strokeWidth: 4, stroke: "rgb(176, 133, 79, 0.5)" },
                focusable: false,
                updatable: false,
            })
        }
    }
    return data
}

import { useCallback } from "react"
import { Handle, Position } from "reactflow"
import Button from "../common/Button"
import { useProjects } from "../../hooks/query"
import { Project, Projects } from "../../api/projects"
import { useIsMobile } from "../../hooks/utils"

function TextUpdaterNode({ data, isConnectable }: { data: any; isConnectable: boolean }) {
    // const onChange = useCallback((evt: any) => {
    //     console.log(evt.target.value)
    // }, [])

    return (
        <div className="text-updater-node">
            <Handle type="target" position={Position.Top} isConnectable={false} />
            <Box>
                <ProjectComponent id={data.project} />
            </Box>
            <Handle type="source" position={Position.Bottom} id="a" isConnectable={false} />
            {/* <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} /> */}
        </div>
    )
}

type TreeProps = {
    initialNodes: Node[]
    initialEdges: Edge[]
}

const Tree: FunctionComponent<TreeProps> = ({ initialNodes, initialEdges }) => {
    // const { setViewport, fitView } = useReactFlow()
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)

    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
    const nodesInitialized = useNodesInitialized({ includeHiddenNodes: true })
    // const [layoutDone, setLayoutDone] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const isMobile = useIsMobile()

    const nodeTypes = useMemo(() => ({ project: TextUpdaterNode }), [])

    const onLayout = useCallback(() => {

        setNodes(nodes)
        setEdges(edges)

        window.requestAnimationFrame(() => {
            setTimeout(() => {
                setLoaded(true)
                // console.log("set viewport")
                // fitView()
                // setTimeout(() => {
                //     // console.log("set viewport")
                //     fitView()
                //     // setViewport({ x: 50, y: -20, zoom: 0.5 })
                // }, 2000)
            }, 0)
        })
    }, [nodes, edges])

    useEffect(() => {
        if (nodesInitialized) {
            onLayout()
        }
    }, [nodesInitialized])

    // translateExtent seems to be causing issues with defaultViewport
    // const translateExtent = useMemo<CoordinateExtent>(() => {
    //     return nodes.reduce(
    //         ([[left, top], [right, bottom]], { position }) => [
    //             [
    //                 Math.min(left, (position ?? { x: Infinity }).x - 1000),
    //                 Math.min(top, (position ?? { y: Infinity }).y - 1000),
    //             ],
    //             [
    //                 Math.max(right, (position ?? { x: -Infinity }).x + nodeWidth + 1000),
    //                 Math.max(bottom, (position ?? { y: -Infinity }).y + nodeHeight + 1000),
    //             ],
    //         ],
    //         [
    //             [Infinity, Infinity],
    //             [-Infinity, -Infinity],
    //         ]
    //     )
    // }, [nodes])

    // const { x, y, zoom } = useViewport()
    // console.log(x, y, zoom)

    return (
        <Box w={"full"} height={800} border="1px solid" borderColor="orange.500">
            {nodes.length && (
                <ReactFlow
                    nodeTypes={nodeTypes}
                    defaultViewport={{ x: 50, y: -160, zoom: 0.5 }}
                    // onLoad={(instance) => setTimeout(() => fitView(), 0)}
                    nodes={nodes}
                    edges={edges}
                    // translateExtent={translateExtent}
                    // fitView
                    proOptions={{ hideAttribution: true }}
                    zoomOnDoubleClick={false}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                >
                    {/* <Panel position="top-right">
                    <button onClick={() => onLayout("TB")}>vertical layout</button>
                    <button onClick={() => onLayout("LR")}>horizontal layout</button>
                    <button onClick={() => fitView()}>fit</button>
                </Panel> */}
                    {!isMobile && (
                        <MiniMap zoomable pannable nodeColor="#6A502F" style={{ backgroundColor: "#B0854F" }} />
                    )}
                </ReactFlow>
            )}
        </Box>
    )
}

const ProjectsTree: FunctionComponent = () => {
    const { data: projects, isLoading, isError } = useProjects()
    const permanent = projects?.permanent
    const initialNodes = useMemo(() => projectsToNode(permanent), [permanent])
    const initialEdges = useMemo(() => projectsToEdges(permanent), [permanent])

    if (!initialNodes.length || !initialEdges.length) return null
    return (
        <ReactFlowProvider>
            {initialNodes.length && <Tree initialNodes={initialNodes} initialEdges={initialEdges} />}
        </ReactFlowProvider>
    )
}

export default ProjectsTree
